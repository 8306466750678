<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.9999 59.1667C13.8912 59.1667 0.833252 46.1088 0.833252 30C0.833252 13.8913 13.8912 0.833374 29.9999 0.833374C46.1087 0.833374 59.1666 13.8913 59.1666 30C59.1666 46.1088 46.1087 59.1667 29.9999 59.1667ZM27.092 41.6667L47.7128 21.043L43.5887 16.9188L27.092 33.4184L18.8407 25.1671L14.7166 29.2913L27.092 41.6667Z" fill="#4368A1"/>
    </svg>
</template>

<script>
export default {
    name: "IconSuccess"
}
</script>

<style scoped>

</style>