<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99974 5.58594L11.9497 0.635941L13.3637 2.04994L8.41374 6.99994L13.3637 11.9499L11.9497 13.3639L6.99974 8.41394L2.04974 13.3639L0.635742 11.9499L5.58574 6.99994L0.635742 2.04994L2.04974 0.635941L6.99974 5.58594Z"/>
    </svg>
</template>

<script>
export default {
    name: "IconCross"
}
</script>

<style scoped>

</style>