<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6687 20C5.2982 20 0.944824 15.523 0.944824 10C0.944824 4.477 5.2982 0 10.6687 0C16.0392 0 20.3926 4.477 20.3926 10C20.3926 15.523 16.0392 20 10.6687 20ZM10.6687 18C12.7318 18 14.7105 17.1571 16.1693 15.6569C17.6282 14.1566 18.4478 12.1217 18.4478 10C18.4478 7.87827 17.6282 5.84344 16.1693 4.34315C14.7105 2.84285 12.7318 2 10.6687 2C8.60555 2 6.6269 2.84285 5.16804 4.34315C3.70918 5.84344 2.8896 7.87827 2.8896 10C2.8896 12.1217 3.70918 14.1566 5.16804 15.6569C6.6269 17.1571 8.60555 18 10.6687 18Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "IconUnchecked"
}
</script>

<style scoped>

</style>