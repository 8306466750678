<template>
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99974 5.17266L11.9497 0.222656L13.3637 1.63666L6.99974 8.00066L0.635742 1.63666L2.04974 0.222656L6.99974 5.17266Z"/>
    </svg>
</template>

<script>
export default {
    name: "IconArrow"
}
</script>

<style scoped>

</style>