<template>
    <div class="app-body">
        <Header :key="language"/>
        <slot/>
        <FooterBlue :key="language"/>
    </div>
</template>

<script setup>
import Header from "../components/blocks/Header";
import FooterBlue from "../components/blocks/FooterBlue";
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore();
const language = computed(() => store.state.currentLanguage);

</script>

<style scoped>

</style>
