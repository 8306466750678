<template>

    <div class="about">
        <div class="container">
            <h2 class="about__title">{{ title }}</h2>
            <div class="about__content">
                <div class="about__content-top">
                    <p v-for="item in top" class="about__text">{{ item }}</p>
                    <button v-if="!opened" class="about__open" @click="open">
                        <span v-if="language === 'ru'">Продолжить</span>
                        <span v-else>Continue</span>
                    </button>
                </div>
                <div class="about__content-bottom" :class="{ '_opened': opened }">
                    <p v-for="item in bottom" class="about__text _bottom" v-html="item"></p>
                    <button v-if="opened" class="about__open" @click="open">
                        <span v-if="language === 'ru'">Скрыть</span>
                        <span v-else>Close</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex"

const name = "About";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);
const title = computed(() => data.main.about.title);
const top = computed(() => data.main.about.top);
const bottom = computed(() => data.main.about.bottom);

let opened = ref(false);

const open = () => {
    opened.value = !opened.value;
}
</script>

<style lang="scss">
@import '../../styles/global.scss';

.about {
    padding: 100px 0 0;

    @media screen and (max-width: ($mobile + 1)) {
        padding: 30px 0 0;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding: 40px 0 0;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 60px;
        color: #2B3D8D;
        text-transform: uppercase;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            text-align: center;
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
        }
    }

    &__text {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: justify;
        color: #333333;
        margin-bottom: 10px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
        }

        & ._bottom {

            &:last-child {
                text-align: right;
            }
        }
    }

    &__open {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: fit-content;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: justify;
        color: #3773B3;
        border: none;
        background-color: transparent;
        text-decoration: underline;
        cursor: pointer;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            line-height: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__content-bottom {
        max-height: 0;
        overflow: hidden;
        transition: max-height .4s ease-in-out;

        &._opened {
            max-height: 2500px;
        }
    }
}
</style>