<template>
    <div class="main-head">
        <div class="container">
            <p class="main-head__date">{{ date }}</p>
            <p class="main-head__subtitle">{{ subtitle }}</p>
            <p class="main-head__title">{{ title }}</p>
            <h1 class="main-head__conf-name">
                <p>{{ conf_name }}</p>
                <span>{{ conf_name_add }}</span>
            </h1>
            <p class="main-head__info">{{ info }}</p>
            <a href="#registration-form" class="main-head__reg button secondary">
                <span v-if="language === 'ru'">Регистрация</span>
                <span v-else>Registration</span>
            </a>
        </div>

    </div>
</template>

<script setup>
import { reactive, computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex"

const name = "MainHead";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);
const date = computed(() => data.main.head.date);
const subtitle = computed(() => data.main.head.subtitle);
const title = computed(() => data.main.head.title);
const conf_name = computed(() => data.main.head.conf_name);
const conf_name_add = computed(() => data.main.head.conf_name_add);
const info = computed(() => data.main.head.info);

</script>

<style lang="scss">
@import '../../styles/global.scss';

.main-head {
    padding: 28px 0 73px;
    background: linear-gradient(273.87deg, #3872B2 21.85%, #2B3D8D 77.8%);
    color: #FFFFFF;

    @media screen and (max-width: ($mobile + 1)) {
        padding: 19px 0;
        overflow: hidden;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding: 30px 0 37px;
        overflow: hidden;
    }

    &__date {
        font-size: 36px;
        font-weight: 300;
        line-height: 60px;
        text-align: center;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 10px;
            font-weight: 300;
            line-height: 14px;
            margin-bottom: 4px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            font-weight: 200;
            line-height: 22px;
            margin-bottom: 6px;
        }
    }

    &__subtitle {
        font-size: 20px;
        font-weight: 500;
        line-height: 39.19px;
        letter-spacing: 0.04em;
        text-align: center;
        margin-bottom: 66px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 10px;
            font-weight: 500;
            line-height: 14px;
            margin-bottom: 19px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 30px;
        }
    }

    &__title {
        position: relative;
        font-size: 39px;
        font-weight: 700;
        line-height: 39.19px;
        letter-spacing: 0.04em;
        text-align: left;
        z-index: 2;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 26px;
            font-weight: 800;
            line-height: 26.24px;
        }
    }

    &__conf-name {
        display: block;
        position: relative;
        font-size: 160px;
        font-weight: 900;
        line-height: 187.5px;
        letter-spacing: 0.04em;
        text-align: left;
        margin: 0;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 64px;
            font-weight: 900;
            line-height: 75px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 107px;
            font-weight: 900;
            line-height: 125.39px;
        }

        & p {
            position: relative;
            z-index: 2;
        }

        & span {
            position: relative;
            display: block;
            font-size: 44px;
            font-weight: 300;
            line-height: 60px;
            letter-spacing: 0.02em;
            text-align: left;
            margin-bottom: 39px;
            z-index: 2;

            @media screen and (max-width: ($mobile + 1)) {
                font-size: 15px;
                font-weight: 300;
                line-height: 22px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                font-family: Roboto;
                font-size: 30px;
                font-weight: 300;
                line-height: 40.17px;
            }
        }

        &:before {
            position: absolute;
            content: '';
            width: 918px;
            height: 516.33px;
            top: -94px;
            left: 23px;
            background-image: url("../../assets/images/bg-head-large.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 1;

            @media screen and (max-width: ($mobile + 1)) {
                width: 308.04px;
                height: 173.26px;
                top: -27px;
                left: 0px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                width: 486.33px;
                height: 273.54px;
                top: -25px;
                left: 114px;
            }
        }

        &:after {
            position: absolute;
            content: '';
            width: 265.95px;
            height: 201.93px;
            top: -53px;
            right: 207px;
            background-image: url("../../assets/images/bg-head-small.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 1;

            @media screen and (max-width: ($mobile + 1)) {
                width: 89.24px;
                height: 67.76px;
                top: -13px;
                left: 201.36px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                width: 140.9px;
                height: 106.98px;
                top: -5px;
                left: 415px;
            }
        }
    }

    &__info {
        position: relative;
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
        text-align: left;
        z-index: 2;

        @media screen and (max-width: ($mobile + 1)) {
            font-family: Roboto;
            font-size: 8.48px;
            font-weight: 300;
            line-height: 12.72px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {}
    }

    &__reg {
        width: 242px;
        margin: 45px auto 0;

        @media screen and (max-width: ($mobile + 1)) {
            width: 190px;
            margin-top: 20px;
        }

        // @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        //     width: 245px;
        // }
    }

}
</style>