<template>
    <div class="app-body">
        <Header :key="language"/>
        <slot/>
        <Footer :key="language"/>
    </div>
</template>

<script setup>
import Header from "../components/blocks/Header";
import Footer from "../components/blocks/Footer";
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore();
const language = computed(() => store.state.currentLanguage);

</script>

<style scoped>

</style>