<template>
    <div class="form-input"
         :class="{'_invalid': inputSpec.error}">
        <input class="form-input__input"
               v-model="inputSpec.model" type="text"
               :placeholder="inputSpec.placeholder"
               autocomplete="disabled"
               @input="onInput"
        >
        <span class="form-input__message">{{ inputSpec.errorMsg }}</span>
    </div>
</template>

<script setup>

    const name = "FormInput";
    const props = defineProps({
        inputSpec: {
            type: Object,
            required: true
        }
    })

    const emits = defineEmits(['input'])

    const onInput = () => {
        emits('input')
    }

</script>

<style lang="scss">
@import '../../styles/global.scss';

.form-input {
    width: 100%;
    height: 35px;
    margin-bottom: 26px;

    @media screen and (max-width: $mobile) {
        margin-bottom: 20px;
    }

    &__input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #FFFFFF;
        background-color: transparent;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        outline: none;

        &::placeholder {
            color: #FFFFFF;
        }

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 17px;
        }
    }

    &__message {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #D52047;
        opacity: 0;

        @media screen and (max-width: $mobile) {
            font-size: 10px;
            line-height: 19px;
        }
    }

    &._invalid {

        & .form-input__input {
            border-bottom: 1px solid #D52047;
        }

        & .form-input__message {
            opacity: 1;
        }
    }
}
</style>