<template>
    <div class="program">
        <div class="container">
            <h2 class="program__title">{{ title }}</h2>
            <div v-for="(block, index) in blocks" class="program__block" :key="`block-${index}`">
                <p class="program__date">{{ block.date }}</p>
                <TopicCard v-for="(topic, ind) in block.topics" :card-spec="topic" :date="block.date"
                    :key="`block-${index}-${ind}`" />
            </div>
        </div>
    </div>

</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex"
import TopicCard from "../blocks/TopicCard.vue";

const name = "Program";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);
const title = computed(() => data.main.program.title);
const blocks = computed(() => data.main.program.blocks);
</script>

<style lang="scss">
@import '../../styles/global.scss';

.program {
    padding-top: 100px;

    @media screen and (max-width: ($mobile + 1)) {
        padding-top: 30px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding-top:60px;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 40px;
        color: #2B3D8D;
        text-transform: uppercase;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }

    &__block {
        padding-bottom: 20px;
    }

    &__date {
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #2B3D8D;
        margin-bottom: 20px;

        @media screen and (max-width: ($mobile + 1)) {
            display: none;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 18px;
            line-height: 18px;
        }
    }
}
</style>