<template>
    <div class="topic-card">
        <div class="topic-card__top" @click="openCardMobile">
            <div class="topic-card__wrap">
                <p class="topic-card__time">{{ date }} {{ cardSpec.time }}</p>
                <div class="topic-card__wrapper">
                    <p v-if="cardSpec.special" class="topic-card__special">{{ cardSpec.special }}</p>
                    <p class="topic-card__type">{{ cardSpec.type }}</p>
                </div>

            </div>
            <p v-if="cardSpec.adress" class="topic-card__adress">{{ cardSpec.adress }}</p>
            <p class="topic-card__name">{{ cardSpec.name }}</p>
            <button class="topic-card__open" :class="{ '_opened': opened }" @click="openCard">
                <IconLargeArrow />
            </button>
        </div>
        <div class="topic-card__bottom" :class="{ '_opened': opened }">
            <div class="topic-card__bottom-wrap">
                <p class="topic-card__desc" v-html="cardSpec.desc"></p>
                <p class="topic-card__moderator">
                    <span v-if="language === 'ru'" class="topic-card__moderator-title">Модератор</span>
                    <span v-else class="topic-card__moderator-title">Moderator</span>
                    <span v-html="cardSpec.moderator"></span>
                </p>
                <p v-if="cardSpec.speaker" class="topic-card__speaker">
                    <span v-if="language === 'ru'" class="topic-card__speaker-title">Спикер</span>
                    <span v-else class="topic-card__speaker-title">Speaker</span>
                    <span v-html="cardSpec.speaker"></span>
                </p>
                <p v-if="cardSpec.participants" class="topic-card__participants">
                    <span v-if="language === 'ru'" class="topic-card__participants-title">Участники</span>
                    <span v-else class="topic-card__participants-title">Participants</span>
                    <span v-for="participant in cardSpec.participants" class="topic-card__participants-item"
                        v-html="participant"></span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex"
import IconLargeArrow from "../../assets/images/icons/IconLargeArrow";


const props = defineProps({
    cardSpec: {
        type: Object,
        required: true
    },

    date: {
        type: String,
        required: true
    }
})

const name = "TopicCard";
const store = useStore();
const language = computed(() => store.state.currentLanguage);

const topic = ref(null);
let opened = ref(false);

const openCard = () => {
    opened.value = !opened.value;
}

const openCardMobile = () => {
    if (window.innerWidth < 441) {
        openCard()
    }
}
</script>

<style lang="scss">
@import '../../styles/global.scss';

.topic-card {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;

    @media screen and (max-width: ($mobile + 1)) {
        margin-bottom: 10px;
    }

    &__top {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        background: linear-gradient(273.87deg, #3872B2 21.85%, #2B3D8D 77.8%);

        @media screen and (max-width: ($mobile + 1)) {
            padding: 10px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            padding: 30px 60px 30px 30px;
        }
    }

    &__wrap {
        display: flex;
        margin-bottom: 20px;

        @media screen and (max-width: ($mobile + 1)) {
            margin-bottom: 14px;
            justify-content: space-between;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            margin-bottom: 30px;
            justify-content: space-between;
        }
    }

    &__wrapper {
        display: flex;

        @media screen and (max-width: ($mobile + 1)) {
            flex-direction: column;
            align-items: flex-end;
            width: 50%;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            flex-wrap: wrap;
            justify-content: flex-end;
            width: 50%;
        }
    }

    &__time {
        font-size: 18px;
        font-weight: 600;
        line-height: 25.2px;
        text-align: left;
        color: #fff;
        margin-right: 26px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 10px;
            line-height: 14px;
            margin-right: 8px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22.4px;
        }
    }

    &__special {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26px;
        padding: 0 17px;
        font-size: 15px;
        font-weight: 500;
        line-height: 17.58px;
        text-align: center;
        color: #fff;
        background-color: #4FA2D6;
        border-radius: 30px;
        margin-right: 15px;

        @media screen and (max-width: ($mobile + 1)) {
            width: 108px;
            margin-bottom: 5px;
            margin-right: 0;
            height: 14px;
            font-size: 10px;
            padding: 0 7px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 110px;
            min-width: 110px;
            padding: 0 10px;
            font-size: 12px;
            margin-right: 7px;
            margin-bottom: 7px;
        }
    }

    &__type {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26px;
        padding: 0 17px;
        font-size: 15px;
        font-weight: 500;
        line-height: 17.58px;
        text-align: center;
        color: #fff;
        background-color: #4FA2D6;
        border-radius: 30px;

        @media screen and (max-width: ($mobile + 1)) {
            height: 14px;
            font-size: 10px;
            padding: 0 7px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 12px;
            margin-bottom: 7px;
            margin-right: 7px;
        }
    }

    &__adress {
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 25.2px;
        text-align: left;
        color: #5DC3EF;
        margin-bottom: 15px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 10px;
            line-height: 14px;
            margin-bottom: 11px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 13px;
        }
    }

    &__name {
        max-width: 940px;
        font-size: 26px;
        font-weight: 400;
        line-height: 36.4px;
        text-align: left;
        color: #fff;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            line-height: 16.8px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 18px;
            line-height: 25.2px;
        }
    }

    &__open {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        bottom: 31px;
        right: 24px;
        background-color: #2B3D8DCC;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        outline: none;

        @media screen and (max-width: ($mobile + 1)) {
            display: none;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            bottom: 23px;
            right: 24px;
        }

        &._opened {

            & svg {
                transform: rotate(180deg);
            }
        }
    }

    &__bottom {
        box-sizing: border-box;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        max-height: 0;
        overflow: hidden;
        transition: all .4s ease-in-out;

        &._opened {
            max-height: 2500px;

        }

        &-wrap {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: #FFFFFF;
            border: 1px solid #8D8D8D;
        }
    }

    &__desc {
        padding: 30px 30px 0 30px;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: justify;
        margin-bottom: 24px;
        color: #333333;

        @media screen and (max-width: ($mobile + 1)) {
            padding: 10px 10px 0 10px;
            font-size: 12px;
            line-height: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__moderator {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        padding: 0 30px 30px 30px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            line-height: 18px;
            padding: 0 10px 10px 10px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }


        &-title {
            display: block;
            font-weight: 600;
            color: #3773B3;
        }
    }

    &__speaker {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        padding: 0 30px 30px 30px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            line-height: 18px;
            padding: 0 10px 10px 10px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }


        &-title {
            display: block;
            font-weight: 600;
            color: #3773B3;
        }
    }

    &__participants {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        padding: 0 30px 30px 30px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            line-height: 18px;
            padding: 0 10px 10px 10px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }


        &-title {
            display: block;
            font-weight: 600;
            color: #3773B3;
        }

        &-item {
            display: block;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
</style>