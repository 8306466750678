<template>
    <section class="registration-main " id="registration-form">
        <!-- <a :href="downloadLink" class="registration__head-rules" download>{{ rules }}</a> -->
        <div class="registration-main__content">
            <div class="container">
                <h2 class="registration-main__title">{{ title }}</h2>
                <form class="registration-main__wrapper" autocomplete="off" method="POST" novalidate
                    v-on:submit.prevent="onSubmit">

                    <FormInput :input-spec="surnameSpec" @input="validateSurname" />
                    <FormInput :input-spec="nameSpec" @input="validateName" />
                    <FormInput v-if="language === 'ru'" :input-spec="patronymSpec" />
                    <FormInput :input-spec="companySpec" @input="validateCompany" />
                    <FormInput :input-spec="titlePositionSpec" @input="validateTitlePosition" />
                    <FormInput :input-spec="countrySpec" />
                    <FormInput :input-spec="emailSpec" @input="validateEmail" />

                    <FormInput :input-spec="phoneSpec" @input="validatePhone" />
                    <FormInput :input-spec="topicSpec" />

                    <FormTextarea :input-spec="textSpec" />
                    <p class="registration-main__text-info">{{ text_info }}</p>

                    <div :class="{ '_active': checkboxActive }" class="registration-main__checkbox" @click="onClick()">
                        <div class="registration-main__checkbox-wrapper">
                            <IconChecked v-if="checkboxActive" />
                            <IconUnchecked v-else />
                        </div>
                        <p class="registration-main__checkbox-text">
                            <span v-if="language === 'ru'">Я даю согласие на обработку моих персональных данных*</span>
                            <span v-else>Consent on personal data processing*</span>
                        </p>
                    </div>


                    <button class="registration-main__submit button secondary" type="submit"
                        :class="{ '_disabled': !formValidated, '_loading': buttonLoading }" :disabled="!formValidated">
                        <PointsLoading v-if="buttonLoading" />
                        <div v-else>
                            <span v-if="language === 'ru'">Отправить</span>
                            <span v-else>Send request</span>
                        </div>
                    </button>

                    <FormResult :error-active="errorActive" :success-active="successActive" @click="closeResult" />
                </form>
            </div>
        </div>
    </section>
</template>

<script setup>
import { currentHost } from "@/api/hosts";
import { reactive, computed, watch, ref, onMounted } from "vue";
import FormInput from "../vue-ui/FormInput";
import FormTextarea from "../vue-ui/FormTextarea"
import FormResult from "../blocks/FormResult";
import PointsLoading from "../vue-ui/PointsLoading.vue"
import IconChecked from "@/assets/images/icons/IconChecked";
import IconUnchecked from "@/assets/images/icons/IconUnchecked";
import { useStore } from "vuex";
import Api from "../../api/api"
// import { useReCaptcha } from "vue-recaptcha-v3";
// const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const name = "RegistrationForm";
const store = useStore();
const api = new Api();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const title = computed(() => data.main.registration.title);
const placeholders = computed(() => data.main.registration.placeholders);
const messages = computed(() => data.main.registration.messages);
const text_info = computed(() => data.main.registration.text_info);

const SITE_KEY = '6Lc5mCYqAAAAACx6GKBdtZKvzyCdYBfrWlb0xd-e'

let downloadLink;

if (language.value === 'ru') {
    downloadLink = `${currentHost}download/ru`;
} else {
    downloadLink = `${currentHost}download/en`;
}

let formValidated = ref(false);
let successActive = ref(false);
let errorActive = ref(false);

let buttonLoading = ref(false);

let checkboxActive = ref(false)


const surnameSpec = reactive({
    model: '',
    name: 'surname',
    placeholder: placeholders.value['surname'],
    error: false,
    errorMsg: '',
    validated: false,
})

const nameSpec = reactive({
    model: '',
    name: 'name',
    placeholder: placeholders.value['name'],
    error: false,
    errorMsg: '',
    validated: false,
})

const patronymSpec = reactive({
    model: '',
    name: 'patronym',
    placeholder: 'Отчество',
})

const companySpec = reactive({
    model: '',
    name: 'company',
    placeholder: placeholders.value['company'],
    error: false,
    errorMsg: '',
    validated: false,
})

const titlePositionSpec = reactive({
    model: '',
    name: 'title_position',
    placeholder: placeholders.value['title_position'],
    error: false,
    errorMsg: '',
    validated: false,
})

const countrySpec = reactive({
    model: '',
    name: 'country',
    placeholder: placeholders.value['country'],
})

const emailSpec = reactive({
    model: '',
    name: 'e_mail',
    placeholder: placeholders.value['email'],
    error: false,
    errorMsg: '',
    validated: false,
    regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
})

const phoneSpec = reactive({
    model: '',
    name: 'phone',
    placeholder: placeholders.value['phone'],
    error: false,
    errorMsg: '',
    validated: true,
    regexp: /^[+]?[0-9]{9,15}$/
})

const topicSpec = reactive({
    model: '',
    name: 'topic',
    placeholder: placeholders.value['topic'],
    error: false,
    errorMsg: '',
    validated: true,

})

const textSpec = reactive({
    model: '',
    name: 'text',
    placeholder: placeholders.value['text'],
})


const validate = (obj) => {
    obj['error'] = false;
    obj['errorMsg'] = '';

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateWithRegexp = (obj, msg) => {
    obj['error'] = false;
    obj['errorMsg'] = '';
    obj['validated'] = false;

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else if (!(obj['regexp'].test(obj['model']))) {
        obj['error'] = true;
        obj['errorMsg'] = msg;
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateSurname = () => {
    validate(surnameSpec)
}

const validateName = () => {
    validate(nameSpec)
}

const validateCompany = () => {
    validate(companySpec)
}

const validateTitlePosition = () => {
    validate(titlePositionSpec)
}

const validateEmail = () => {
    validateWithRegexp(emailSpec, messages.value['email'])
}

const validatePhone = () => {
    validateWithRegexp(phoneSpec, messages.value['phone'])
}


const onChange = (val) => {
    curTopic.value = val;
}

const onClick = () => {
    checkboxActive.value = !checkboxActive.value;
};

const onSubmit = () => {
    postData()
}

const postData = (token) => {
    successActive.value = false;
    errorActive.value = false;
    buttonLoading.value = true;

    api.postRegData({
        surname: surnameSpec.model,
        name: nameSpec.model,
        patronym: patronymSpec.model,
        company: companySpec.model,
        title_position: titlePositionSpec.model,
        country: countrySpec.model,
        email: emailSpec.model,
        phone: phoneSpec.model,
        text: textSpec.model,
        topic: topicSpec.model,
        lang: language.value,
        token: token
    }).then((status) => {
        if (status === 200) {
            successActive.value = true;
            buttonLoading.value = false;
        } else {
            errorActive.value = true;
            buttonLoading.value = false;
        }
        console.log(status);
    });
}

const closeResult = () => {
    successActive.value = false;
    errorActive.value = false;
}


watch([surnameSpec, nameSpec, companySpec, titlePositionSpec, emailSpec, phoneSpec, checkboxActive], (newValue, oldValue) => {
    if (surnameSpec.validated && nameSpec.validated && companySpec.validated && titlePositionSpec.validated && emailSpec.validated && phoneSpec.validated && checkboxActive.value) {
        formValidated.value = true;
    } else {
        formValidated.value = false;
    }
})


</script>

<style lang="scss">
@import '../../styles/global.scss';

.registration-main {
    width: 100%;
    background: linear-gradient(273.87deg, #3872B2 21.85%, #2B3D8D 77.8%);
    padding: 100px 0;

    @media screen and (max-width: ($mobile + 1)) {
        padding: 30px 0;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding: 50px 0;
    }

    &__content {
        width: 100%;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 35px;
        color: #FFFFFF;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            margin-bottom: 15px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 25px;
        }
    }

    &__wrapper {
        position: relative;
        width: 695px;
        box-sizing: border-box;
        padding-bottom: 45px;
        margin: 0 auto;

        @media screen and (max-width: ($mobile + 1)) {
            width: 100%;
            padding: 20px 0;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 100%;
            padding: 30px 0;
            max-width: 700px;
        }
    }

    & .form-textarea {
        margin-bottom: 0;
    }

    &__text-info {
        color: #FFFFFF;
        font-size: 12px;
    }

    &__checkbox {
        display: flex;
        justify-content: center;
        margin: 50px auto 30px;
        align-items: center;
        cursor: pointer;
        transition: color .3s ease;

        @media screen and (max-width: $mobile) {
            margin: 30px auto;
        }

        &-wrapper {
            width: 20px;
            height: 20px;
        }

        &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #FFFFFF;
            margin-left: 16px;
        }
    }

    &__submit {
        width: 256px;
        margin: 0 auto;

        @media screen and (max-width: $mobile) {
            width: 190px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 245px;
        }

        &._loading {
            pointer-events: none;
        }
    }

    &__captcha {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
}
</style>