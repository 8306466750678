<template>
    <section class="biography">
        <div class="container">
            <div class="biography__container">
                <div class="biography__top">
                    <img class="biography__img" src="../../assets/images/efimov.png" alt="Efimov A.N." />
                    <div class="biography__text">
                        <p class="biography__desc">{{ biography.short }}</p>
                        <button class="biography__open" :class="{ '_opened': opened }" @click="openBio">
                            <p class="_desktop">
                                <span v-if="language === 'ru'">Биография Анатолия Николаевича Ефимова</span>
                                <span v-else>Biography of Anatoly N. Efimov</span>
                            </p>
                            <p class="_mobile">
                                <span v-if="language === 'ru'">Биография А.Н. Ефимова</span>
                                <span v-else>Biography of A.N. Efimov</span>
                            </p>
                            <IconArrow />
                        </button>
                    </div>

                </div>
                <div class="biography__bottom" :class="{ '_opened': opened }">
                    <p v-for="item in biography.bio">
                        {{ item }}
                    </p>
                </div>
            </div>
            <div class="biography__wrap">
                <!-- <a href="http://www.vavt.ru/rio/site/for_sdud" class="biography__btn button secondary">
                    <span v-if="language === 'ru'">Требования к научным публикациям</span>
                    <span v-else>Requirements for scientific published paper</span>
                </a> -->

                <a href="https://conference.vavt.ru/2022" class="biography__btn" target="_blank">
                    <span v-if="language === 'ru'">Ефимовские чтения 2022</span>
                    <span v-else>Conference 2022</span>
                </a>
                <a v-if="language === 'ru'" href="https://conference.vavt.ru/2023/ru" class="biography__btn" target="_blank">
                    <span >Ефимовские чтения 2023</span>
                </a>

                <a v-else href="https://conference.vavt.ru/2023/en" class="biography__btn" target="_blank">
                    <span >Conference 2023</span>
                </a>

            </div>

        </div>
    </section>
    <!--    <Output/>-->
</template>

<script setup>
import IconArrow from "../../assets/images/icons/IconArrow";
import { useStore } from "vuex";
import { computed, ref } from "vue";
//import Output from "@/components/Output";

const name = "Biography";
const store = useStore();

const language = computed(() => store.state.currentLanguage);
const data = store.state.currentData;
const biography = computed(() => data.main.biography);
let opened = ref(false);

const openBio = () => {
    opened.value = !opened.value;
}
</script>

<style lang="scss">
@import '../../styles/global.scss';

.biography {
    width: 100%;
    padding-bottom: 100px;

    @media screen and (max-width: ($mobile + 1)) {
        padding-bottom: 40px;
    }

    &__container {
        width: 100%;
        margin: 0 auto;
        padding: 60px 91px;
        margin-bottom: 37px;
        margin-top: 100px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;

        @media screen and (max-width: ($mobile + 1)) {
            padding: 25px 0;
            margin-bottom: 30px;
            margin-top: 30px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            padding: 35px 40px;
            margin-bottom: 80px;
            margin-top: 60px;
        }
    }

    &__top {
        display: flex;

        @media screen and (max-width: $tablet-middle) {
            flex-direction: column;
        }
    }

    &__img {
        display: block;
        width: 198px;
        height: 198px;
        margin-right: 50px;

        @media screen and (max-width: $tablet-middle) {
            width: 198px;
            height: 198px;
            margin: 0 auto 30px;
        }

    }

    &__desc {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        text-align: left;
        margin-bottom: 66px;
        margin-top: 8px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 14px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 18px;
        }
    }

    &__open {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 505px;
        height: 55px;
        padding-left: 26px;
        font-weight: 500;
        font-size: 20px;
        line-height: 23.44px;
        color: #333333;
        background-color: transparent;
        transition: all .3s ease;
        outline: none;
        border: 1px solid #333333;
        cursor: pointer;
        border-radius: 40px;

        & ._mobile {
            display: none;
        }

        @media screen and (max-width: $mobile) {
            font-size: 16px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 20px;
        }

        @media screen and (max-width: $tablet-max) {
            & ._desktop {
                display: none;
            }

            & ._mobile {
                display: block;
            }
        }

        & span {
            display: block;
            margin-right: 16px;
        }

        & svg {
            transition: all .3s ease;
        }

        & svg path {
            fill: #4FA2D6;
            transition: all .3s ease;
        }

        &:hover {
            color: #163258;

            & svg path {
                fill: #163258;
            }
        }

        &._opened {

            & svg {
                transform: rotate(180deg);
            }
        }
    }

    &__bottom {
        max-height: 0;
        overflow: hidden;
        transition: max-height .4s ease-in-out;

        &._opened {
            max-height: 2500px;
        }

        & p {
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: #333333;
            margin-bottom: 10px;

            @media screen and (max-width: $mobile) {
                font-size: 14px;
            }

            &:first-of-type {
                margin-top: 20px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    // &__wrap {
    //     display: flex;
    //     justify-content: space-between;

    //     @media screen and (max-width: $tablet-middle) {
    //         flex-direction: column;
    //         justify-content: flex-start;
    //     }
    // }

    &__btn {
        display: block;
        font-size: 20px;
        font-weight: 500;
        line-height: 34px;
        text-align: left;
        color: #333333;
        margin-bottom: 20px;


        @media screen and (max-width: $tablet-middle) {
            width: 100%;
            margin-bottom: 15px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
            width: 45%;
        }
    }
}
</style>