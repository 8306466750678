<template>
    <div class="contest-winners">
        <div class="container">
            <h2 class="contest-winners__title">{{ title }}</h2>
            <p class="contest-winners__subtitle">{{ subtitle }}</p>
            <p v-for="item in list" class="contest-winners__list-item" v-html="item"></p>
            <p class="contest-winners__desc">{{ desc }}</p>
            <p class="contest-winners__statement-head">{{ statementHead }}</p>
            <p v-for="item in statementList" class="contest-winners__statement-item">{{ item }}</p>
            <p class="contest-winners__congratulations">{{ congratulations }}</p>

            <div class="contest-winners__wrap">
                <a href="mailto:efimov-konf@vavt.ru" class="contest-winners__link">{{ links.mail }}</a>
                <a :href="downloadLink" class="contest-winners__link" download>{{ links.download }}</a>
            </div>
            <div class="contest-winners__line"></div>
        </div>
    </div>

</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import { currentHost } from "@/api/hosts";

const name = "ContestWinners";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const title = computed(() => data.competition.winners.title);
const subtitle = computed(() => data.competition.winners.subtitle);
const list = computed(() => data.competition.winners.list);

const desc = computed(() => data.competition.winners.desc);
const statementHead = computed(() => data.competition.winners.statement.head);
const statementList = computed(() => data.competition.winners.statement.list);
const congratulations = computed(() => data.competition.winners.statement.congr);

const links = computed(() => data.competition.winners.links);

let downloadLink;

if (language.value === 'ru') {
    downloadLink = `${currentHost}download/ru`;
} else {
    downloadLink = `${currentHost}download/en`;
}

</script>


<style lang="scss">
@import '../../styles/global.scss';

.contest-winners {
    padding: 100px 0 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;

    @media screen and (max-width: ($mobile + 1)) {
        padding: 40px 0 0;
        font-size: 13px;
        line-height: 18px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding: 40px 0 0;
        font-size: 16px;
        line-height: 22px;
    }

    &__title {
        max-width: 940px;
        font-size: 38px;
        font-weight: 500;
        line-height: 50px;
        text-align: center;
        margin: 0 auto 56px;
        color: #2B3D8D;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 30px;
            max-width: 544px;
        }
    }

    &__subtitle {
        font-weight: 700;
        color: #2B3D8D;
        margin-bottom: 30px;
    }

    &__list-item {
        position: relative;
        padding-left: 30px;

        &:before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 11px;
            left: 7px;
            background-color: #333;
            border-radius: 50%;
        }
    }

    &__desc {
        margin: 30px 0;
    }

    &__statement-item {
        position: relative;
        padding-left: 30px;

        &:before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 11px;
            left: 7px;
            background-color: #333;
            border-radius: 50%;
        }
    }

    &__congratulations {
        margin-top: 30px;
        font-weight: 700;
        margin-bottom: 34px;
    }

    &__wrap {
        display: flex;
        width: fit-content;
        margin: 0 auto;
    }

    &__link {
        font-size: 24px;
        font-weight: 400;
        line-height: 50px;
        text-align: center;
        color: #4FA2D6;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 13px;
            line-height: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }

        &:first-of-type {
            margin-right: 30px;
        }
    }

    &__line {
        width: 100%;
        padding-top: 60px;
        border-bottom: 1px solid #333;

        @media screen and (max-width: ($mobile + 1)) {
            padding-top: 40px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            padding-top: 40px;
        }
    }

}
</style>