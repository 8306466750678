<template>
    <div>
        <component :is="layoutComponent " v-bind="layoutProps">
            <router-view/>
        </component>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import router from "@/router";
import DefaultLayout from "./layouts/Default.vue"
import ContestLayout from "./layouts/Contest.vue"

const name = "App";

const store = useStore();
const route = useRoute();

const lang = route.params.lang
const path = route.path;

if (!lang) {
    store.dispatch('changeLanguage', 'ru')
    router.replace({ name: route.name, params: { lang: 'ru' } })
} else if (lang === 'en') {
    store.dispatch('changeLanguage', 'en')
    router.replace({ name: route.name, params: { lang: 'en' } })
}

const layoutComponent = computed(() => {
  return route.meta.layout === 'contest-layout' ? ContestLayout : DefaultLayout;
});

const layoutProps = computed(() => {
  return route.meta.layoutProps || {};
});

</script>

<style lang="scss">

</style>

