<template>
    <svg width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3333 17.8828L10.3333 6.44443C10.3333 6.01106 10.0875 5.59544 9.64988 5.28899C9.21229 4.98255 8.61881 4.8104 7.99998 4.8104C7.38114 4.8104 6.78766 4.98255 6.35008 5.28899C5.9125 5.59544 5.66667 6.01106 5.66667 6.44443L5.66667 21.1509C5.66667 22.9114 7.756 24.3386 10.3333 24.3386C12.9107 24.3386 15 22.9114 15 21.1509L15 6.24077C15 4.94064 14.2625 3.69376 12.9497 2.77443C11.637 1.8551 9.85652 1.33862 8 1.33862C6.14348 1.33862 4.36301 1.8551 3.05025 2.77443C1.7375 3.69376 1 4.94064 1 6.24077L1 17.6791" stroke="white" stroke-width="1.48085" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "IconUpload"
}
</script>

<style scoped>

</style>