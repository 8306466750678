<template>
    <div class="contest-head">
        <div class="container">
            <p class="contest-head__name">{{ conf_name }}</p>
            <p class="contest-head__add">{{ conf_name_add }}</p>
            <p class="contest-head__subtitle">{{ subtitle }}</p>
            <p class="contest-head__title">{{ title }}</p>
            <div class="contest-head__date">{{ date }}</div>
            <div class="contest-head__banner">
                <img src="../../assets/images/banner/banner-bg-6.png" class="contest-head__img">
                <img src="../../assets/images/banner/banner-bg-1.png" class="contest-head__img">
                <img src="../../assets/images/banner/banner-bg-2.png" class="contest-head__img">
                <img src="../../assets/images/banner/banner-bg-3.png" class="contest-head__img">
                <img src="../../assets/images/banner/banner-bg-4.png" class="contest-head__img">
                <img src="../../assets/images/banner/banner-bg-5.png" class="contest-head__img">
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const name = "ContestHead";
const store = useStore();

const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const conf_name = computed(() => data.competition.head.conf_name);
const conf_name_add = computed(() => data.competition.head.conf_name_add);
const subtitle = computed(() => data.competition.head.subtitle);
const title = computed(() => data.competition.head.title);
const date = computed(() => data.competition.head.date);
const invite = computed(() => data.competition.head.invite);
const rules = computed(() => data.competition.head.rules);
</script>

<style lang="scss">
@import '../../styles/global.scss';

.contest-head {
    width: 100%;
    background: linear-gradient(273.87deg, #3872B2 21.85%, #2B3D8D 77.8%);
    padding-top: 69px;
    padding-bottom: 83px;

    @media screen and (max-width: ($mobile + 1)) {
        padding-top: 23px;
        padding-bottom: 17px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding-top: 41px;
        padding-bottom: 50px;
    }


    &__name {
        font-size: 20px;
        font-weight: 500;
        line-height: 39.19px;
        letter-spacing: 0.04em;
        text-align: center;
        margin-bottom: 6px;
        color: #FFFFFF;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 9px;
            font-weight: 500;
            line-height: 16px;
            margin-bottom: 3px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 12px;
            font-weight: 500;
            line-height: 23.52px;
            margin-bottom: 4px;
        }
    }

    &__add {
        font-family: Roboto;
        font-size: 40px;
        font-weight: 800;
        line-height: 39.19px;
        letter-spacing: 0.04em;
        text-align: center;
        margin-bottom: 39px;
        color: #FFFFFF;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            margin-bottom: 7px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 800;
            line-height: 23.52px;
            margin-bottom: 23px;
        }
    }

    &__subtitle {
        width: 600px;
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        color: #5DC3EF;
        margin: 0 auto 20px;

        @media screen and (max-width: ($mobile + 1)) {
            width: 290px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            margin: 0 auto 7px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 100%;
            max-width: 500px;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            margin: 0 auto 12px;
        }
    }

    &__title {
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
        text-align: center;
        color: #5DC3EF;
        margin-bottom: 40px;

        @media screen and (max-width: ($mobile + 1)) {
            min-width: 271px;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            margin-bottom: 7px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            max-width: 740px;
            font-size: 24px;
            font-weight: 600;
            line-height: 30px;
            text-align: center;
            margin: 0 auto 24px;

        }
    }

    &__date {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 377px;
        height: 49px;
        border-radius: 40px;
        background-color: #3773B3;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.44px;
        text-align: center;
        margin: 0 auto 60px;

        @media screen and (max-width: $mobile) {
            width: 170px;
            height: 22px;
            font-size: 9px;
            line-height: 11px;
            text-align: center;
            margin: 0 auto 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 226px;
            height: 29px;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            margin: 0 auto 36px;
        }

    }


    &__banner {
        display: flex;
        justify-content: space-between;
        width: 968px;
        margin: 0 auto;

        @media screen and (max-width: ($mobile + 1)) {
            width: 186px;
            flex-wrap: wrap;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
            width: 290px;
            flex-wrap: wrap;
        }

        @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
            width: 580px;
        }
    }

    &__img {
        display: block;
        width: 153px;
        height: 153px;

        @media screen and (max-width: ($mobile + 1)) {
            width: 59px;
            height: 59px;
            margin-bottom: 5px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 92px;
            height: 92px;
            margin-bottom: 6px;
        }
    }
}
</style>