<template>
    <div class="form-result" :class="{'_active': errorActive || successActive}">
        <div class="form-result__wrapper" :class="{'_active': successActive}">
            <IconSuccess/>
            <p class="form-result__text lt">Спасибо! <br/>
                Ваше сообщение отправлено.</p>
        </div>

        <div class="form-result__wrapper" :class="{'_active': errorActive}">
            <IconError/>
            <p class="form-result__text lt">Что-то пошло не так. <br/> Повторите попытку позже.</p>
        </div>
        <div class="form-result__cross"
             @click="closeResult">
            <IconCross/>
        </div>
    </div>
</template>

<script setup>
import IconCross from "../../assets/images/icons/IconCross";
import IconSuccess from "../../assets/images/icons/IconSuccess";
import IconError from "../../assets/images/icons/IconError";

  const name = "FormResult";

  const props = defineProps({
      successActive: {
          type: Boolean
      },

      errorActive: {
          type: Boolean
      }
  })

const emits = defineEmits(['click'])

const closeResult = () => {
      emits('click')
}
</script>

<style lang="scss">
@import '../../styles/global.scss';

.form-result {
    display: none;
    position: absolute;
    bottom: 200px;
    left: 90px;
    flex-direction: column;
    align-items: center;
    width: 520px;
    height: 298px;
    padding: 32px 40px 24px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity .2s ease;
    z-index: 1;

    &._active {
        display: flex;
        opacity: 1;
        animation: formAppearing .5s ease-in;
        animation-fill-mode: forwards;
    }

    @media screen and (max-width: $mobile) {
        width: 100%;
        height: 250px;
        left: 0;
        padding: 20px 25px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: 550px) {
        width: 100%;
        max-width: 520px;
        left: 0;
        padding: 25px 30px;
    }

    @media screen and (min-width: 550px) and (max-width: $tablet-max) {
        left: calc((100% - 520px)/2)
    }

    &__wrapper {
        display: none;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        opacity: 0;
        transition: opacity .2s ease;
        z-index: 1;

        & svg {
            margin-top: 60px;
            margin-bottom: 40px;

            @media screen and (max-width: $mobile) {
                margin-top: 40px;
                margin-bottom: 20px;
            }

        }

        &._active {
            display: flex;
            opacity: 1;
            animation: formAppearing .5s ease-in;
            animation-fill-mode: forwards;
        }
    }

    &__text  {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #333333;
        max-width: 340px;
        margin: 0 auto 0;

        @media screen and (max-width: $mobile) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &__cross {
        position: absolute;
        width: 13px;
        height: 13px;
        top: 19px;
        right: 23px;
        cursor: pointer;
        z-index: 5;

        & svg path {
            transition: all .3s ease;
            fill: #C7D0E2;
        }

        &:hover {
            & svg path {
                fill: darken(#C7D0E2, 10%);
            }
        }
    }
}

@keyframes formAppearing {
    0% {
        opacity: 0;
        transform: scale(.7)
    }
    60% {
        opacity: 1;
        transform: scale(.95)
    }
    75% {
        opacity: 1;
        transform: scale(.92)
    }
    90% {
        opacity: 1;
        transform: scale(1.03)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes formDisappearing {
    0% {
        transform: scale(1)
    }
    to {
        opacity: 0;
        transform: scale(1.2)
    }
}
</style>