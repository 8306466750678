import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Main from "../views/Main.vue";
import Contest from "../views/Contest.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:lang?",
    meta: {
      layout: "default-layout",
    },

    children: [
      {
        path: '',
        name: 'Main',
        component: Main,
      },
      {
        path: '2024',
        name: 'Contest',
        component: Contest,
        meta: {
          layout: "contest-layout",
        },
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
