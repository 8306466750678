import {createStore} from 'vuex'
// @ts-ignore
import data from "./data.js"

export default createStore({
    state: {
        currentData: data['ru'],
        currentLanguage: 'ru'
    },
    mutations: {
        changeLanguage(state, val) {
            state.currentLanguage = val;
            state.currentData = data[val];
        },
    },
    actions: {
        changeLanguage(context, val) {
            context.commit('changeLanguage', val)
        }
    },
    getters: {},
});





