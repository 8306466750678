<template>
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.99962 7.56353L15.5996 0.963531L17.485 2.84886L8.99962 11.3342L0.514282 2.84886L2.39962 0.963531L8.99962 7.56353Z"
            fill="white" />
    </svg>
</template>

<script>
export default {
    name: "IconLargeArrow"
}
</script>

<style scoped></style>