<template>
    <div class="main-contest">
        <div class="container">
            <div class="main-contest__wrapper">
                <p class="main-contest__subtitle">{{ subtitle }}</p>
                <h2 class="main-contest__title">{{ title }}</h2>
                <a v-if="language === 'ru'" href="ru/2024" class="main-contest__link">{{ link }}</a>
                <a v-else href="en/2024" class="main-contest__link">{{ link }}</a>
            </div>
            <!-- <ul class="main-contest__list">
                <li v-for="item in list" class="main-contest__item">
                    <p class="main-contest__item-title">{{ item.title }}</p>
                    <p class="main-contest__item-text" v-html="item.text"></p>
                </li>
            </ul>
            <a v-if="language === 'ru'" href="ru/2024" class="main-contest__button">{{ button }}</a>
            <a v-else href="en/2024" class="main-contest__button">{{ button }}</a> -->
        </div>
    </div>
</template>

<script setup>
import { reactive, computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex"

const name = "MainContest";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const subtitle = computed(() => data.main.contest_info.subtitle);
const title = computed(() => data.main.contest_info.title);
const link = computed(() => data.main.contest_info.link);
const list = computed(() => data.main.contest_info.list);
const button = computed(() => data.main.contest_info.button);

</script>

<style lang="scss">
@import '../../styles/global.scss';

.main-contest {
    // padding-bottom: 100px;

    @media screen and (max-width: ($mobile + 1)) {
        padding-bottom: 20px;
    }

    &__wrapper {
        position: relative;
        width: 100%;
        height: 465px;
        padding: 59px 84px 47px 67px;
        box-sizing: border-box;
        background-image: url("../../assets/images/main-contest-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: #fff;
        margin-bottom: 50px;
        border-radius: 10px;

        @media screen and (max-width: ($mobile + 1)) {
            padding: 22px 21px 17px 10px;
            min-height: 119px;
            height: calc((100vw - 50px) * 0.44);
            border-radius: 2.5px;
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
            border-radius: 7px;
            margin: 0 auto 30px;
            padding: 40px 44px 30px 30px;
            height: calc((100vw - 50px) * 0.44);
        }

        @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
            border-radius: 7px;
            margin: 0 auto 30px;
            padding: 104px 56px 33px 45px;
            height: calc((100vw - 50px) * 0.44);
        }

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(90.55deg, #2B3D8D 4.31%, rgba(0, 0, 0, 0.17) 99.63%);
            border-radius: 10px;

            @media screen and (max-width: ($mobile + 1)) {
                border-radius: 2.5px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                border-radius: 7px;
            }
        }
    }

    &__subtitle {
        position: relative;
        font-size: 44px;
        font-weight: 300;
        line-height: 60px;
        letter-spacing: 0.02em;
        text-align: left;
        margin-bottom: 23px;
        z-index: 2;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 10px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 15px;
        }

        @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
            font-size: 28px;
            line-height: 40px;
        }

    }

    &__title {
        position: relative;
        font-size: 44px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 0.02em;
        text-align: left;
        margin-top: 0;
        margin-bottom: 37px;
        z-index: 2;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-align: left;
            margin-bottom: 0;
            max-width: 231px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
            font-size: 20px;
            line-height: 23px;
        }

        @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
            font-size: 28px;
            line-height: 40px;
        }
    }

    &__link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 224px;
        height: 60px;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.44px;
        text-align: center;
        color: #fff;
        background-color: #3773B3;
        text-decoration: none;
        border-radius: 40px;
        z-index: 2;

        @media screen and (max-width: $tablet-max) {
            display: none;
        }
    }

    &__list {
        list-style: none;
        padding: 0;
    }

    &__item {
        margin-bottom: 35px;

        @media screen and (max-width: ($mobile + 1)) {
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {}


        &-title {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            text-align: left;
            margin-bottom: 5px;
            color: #2B3D8D;

            @media screen and (max-width: ($mobile + 1)) {
                font-size: 12px;
                font-weight: 700;
                line-height: 18px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {}
        }

        &-text {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            color: #333333;

            @media screen and (max-width: ($mobile + 1)) {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {}

        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 256px;
        height: 60px;
        margin: 50px auto 0;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.44px;
        text-align: center;
        color: #fff;
        background-color: #3773B3;
        border-radius: 40px;
        border: none;
        outline: none;
        cursor: pointer;
        text-decoration: none;

        @media screen and (max-width: ($mobile + 1)) {
            width: 160px;
            height: 36px;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.06px;
            margin: 20px auto 0;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {}

    }

}
</style>