<template>
    <header class="header">
        <div class="header__container container">
            <div class="header__left-wrapper">
                <a v-if="language === 'ru'" href="http://www.vavt.ru/" class="header__logo-vavt">
                    <IconLogo />
                </a>
                <a v-else href="http://www.vavt.ru/" class="header__logo-vavt _en">
                    <IconLogoEng />
                </a>
                <a href="/" class="header__logo-efimov">
                </a>
            </div>
            <div class="header__right-wrapper">
                <button class="header__btn-ru lang" :class="{ '_active': language === 'ru' }"
                    @click="changeLanguageRu">RU
                </button>
                <button class="header__btn-eng lang" :class="{ '_active': language === 'en' }"
                    @click="changeLanguageEn">ENG
                </button>
            </div>
        </div>
    </header>
</template>

<script setup>
import IconLogo from "../../assets/images/icons/IconLogo";
import IconLogoEng from "@/assets/images/icons/IconLogoEng";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import router from "@/router";

const name = "Header";
const store = useStore();
const route = useRoute();

const language = computed(() => store.state.currentLanguage);

const changeLanguageRu = () => {
    store.dispatch('changeLanguage', 'ru')
    router.replace({ name: route.name, params: { lang: 'ru' } })
}

const changeLanguageEn = () => {
    store.dispatch('changeLanguage', 'en')
    router.replace({ name: route.name, params: { lang: 'en' } })
}

</script>

<style lang="scss">
@import '../../styles/global.scss';

.header {
    width: 100%;
    height: 140px;
    box-sizing: border-box;
    padding-top: 26px;
    padding-bottom: 34px;

    @media screen and (max-width: ($mobile + 1)) {
        padding: 15px 0;
        height: 68px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding: 20px 0;
        height: 110px;
    }

    &__container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: ($mobile + 1)) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__left-wrapper {
        display: flex;
        align-items: flex-end;
    }

    &__right-wrapper {
        display: flex;
        align-items: flex-end;

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    &__logo-vavt {
        width: 303px;
        height: 80px;
        margin-right: 31px;

        & svg path {
            fill: #163258;
        }

        @media screen and (max-width: ($mobile + 1)) {
            width: 142px;
            height: 38px;
            margin-right: 7px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 256px;
            height: 68px;
            margin-right: 15px;
        }

        &._en {
            width: 222px;
            height: 80px;

            @media screen and (max-width: ($mobile + 1)) {
                width: 106px;
                height: 38px;
                margin-right: 15px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                width: 190px;
                height: 68px;
            }
        }
    }

    &__logo-efimov {
        width: 92px;
        height: 71px;
        background-image: url("../../assets/images/ef-logo-desktop.svg");
        background-size: cover;
        background-repeat: no-repeat;

        @media screen and (max-width: ($mobile + 1)) {
            width: 44px;
            height: 34px;
            background-image: url("../../assets/images/ef-logo-mob.svg");
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 78px;
            height: 61px;
            background-image: url("../../assets/images/ef-logo-tablet.svg");
        }

        &-text {
            width: 328px;
            margin-left: 19px;
            color: #163258;
            font-size: 15px;
            font-weight: 600;
            line-height: 19px;
            text-align: left;
            text-transform: uppercase;

            @media screen and (max-width: ($mobile + 1)) {
                display: none;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
                display: none;
            }

            @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
                max-width: 250px;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
            }
        }

    }

    &__btn-ru {
        margin-right: 6px;

        @media screen and (max-width: ($mobile + 1)) {
            margin-right: 5px;

        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
}
</style>