<template>
    <footer class="footer-blue">
        <div class="container">
            <div class="footer-blue__container">
                <div class="footer-blue__text">
                    <p class="footer-blue__copyright">© 1999-2024, VAVT.ru</p>
                    <p class="footer-blue__name">{{ footer.name }}</p>
                    <p class="footer-blue__adress" v-html="footer.adress">
                    </p>
                </div>
                <a v-if="language==='ru'" href="http://www.vavt.ru/" class="footer-blue__logo">
                    <IconLogo/>
                </a>
                <a v-else href="http://www.vavt.ru/" class="footer-blue__logo _en">
                    <IconLogoEng/>
                </a>
            </div>
        </div>
    </footer>
</template>

<script setup>
import IconLogo from "@/assets/images/icons/IconLogo";
import IconLogoEng from "@/assets/images/icons/IconLogoEng";
import {computed} from "vue";
import {useStore} from "vuex";

const name = "FooterBlue";
const store = useStore();

const data = store.state.currentData;
const footer = computed(() => data.footer);
const language = computed(() => store.state.currentLanguage);

</script>

<style lang="scss">
@import '../../styles/global.scss';

.footer-blue {
    width: 100%;
    height: 225px;
    box-sizing: border-box;
    padding: 57px 0 52px;
    background: linear-gradient(273.87deg, #3872B2 21.85%, #2B3D8D 77.8%);


    @media screen and (max-width: $mobile) {
        height: auto;
        padding: 26px 0;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        height: auto;
        padding: 34px 0;
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $mobile) {
            min-width: 290px;
            max-width: 326px;
            width: auto;
            margin: 0 auto;
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            max-width: 418px;
            width: auto;
            margin: 0 auto;
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        color: #fff;
        max-width: 430px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 18px;
        }
    }

    &__copyright {
        font-weight: 700;
    }

    &__logo {
        width: 303px;
        height: 80px;

        @media screen and (max-width: $mobile) {
            width: 260px;
            height: 69px;
            margin-bottom: 28px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 303px;
            height: 80px;
            margin-bottom: 26px;
        }

        & svg path {
            fill: #fff;
        }

        &._en {
            width: 222px;
            height: 80px;

            @media screen and (max-width: $mobile) {
                width: 191px;
                height: 69px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                width: 222px;
                height: 80px;
            }
        }
    }
}
</style>