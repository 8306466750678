<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 59.1666C13.8913 59.1666 0.833374 46.1087 0.833374 29.9999C0.833374 13.8912 13.8913 0.833252 30 0.833252C46.1088 0.833252 59.1667 13.8912 59.1667 29.9999C59.1667 46.1087 46.1088 59.1666 30 59.1666ZM27.0834 38.7499V44.5832H32.9167V38.7499H27.0834ZM27.0834 15.4166V32.9166H32.9167V15.4166H27.0834Z" fill="#803D3D"/>
    </svg>
</template>

<script>
export default {
    name: "IconError"
}
</script>

<style scoped>

</style>