import { createApp } from 'vue'
import './styles/main.scss'
import App from './App.vue'
import DefaultLayout from "./layouts/Default.vue"
import ContestLayout from "./layouts/Contest.vue"
import router from './router'
import store from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'


const app = createApp(App)
app.use(store)
app.use(router)
// app.use(VueReCaptcha, { siteKey: '6LcHfxQiAAAAAHfXaBsgaFu3lOCDCf5sLQj4XHBV' })
app.component("default-layout", DefaultLayout)
app.component("contest-layout", ContestLayout)

router.isReady().then(() => {
    app.mount('#app')
})