<template>
    <div class="form-textarea"
         :class="{'_invalid': inputSpec.error}">
                            <textarea class="form-textarea__textarea"
                                      v-model="inputSpec.model"
                                      :placeholder="inputSpec.placeholder"
                                      @input="onInput">
                            </textarea>
        <span class="form-textarea__message">{{ inputSpec.errorMsg }}</span>
    </div>
</template>

<script setup>


const name = "FormTextarea";
const props = defineProps({
    inputSpec: {
        type: Object,
        required: true
    }
})

const emits = defineEmits(['input'])

const onInput = () => {
    emits('input')
}
</script>

<style lang="scss">
@import '../../styles/global.scss';

.form-textarea {
    position: relative;
    width: 100%;
    margin-top: 37px;
    margin-bottom: 30px;

    &__textarea {
        width: 100%;
        height: 266px;
        padding: 23px 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #C7D0E2;
        border-radius: 5px;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #555555;
        outline: none;
        resize: none;

        @media screen and (max-width: $mobile) {
            height: 200px;
            font-size: 16px;
        }
    }

    &__message {
        font-weight: 400;
        font-size: 11px;
        line-height: 22px;
        color: #D52047;
        position: absolute;
        bottom: -24px;
        left: 0;
        opacity: 0;
        transition: all .3s ease;
    }

    &._invalid {

        & .form-textarea__textarea {
            border: 1px solid #D52047;
        }

        & .form-textarea__message {
            opacity: 1;
        }
    }



}
</style>