<template>
    <div class="main">
        <MainHead :key="language" />
        <About v-if="language === 'ru'" :key="language"/>
        <Program :key="language"/>
        <!-- <Directions :key="language" /> -->
        <MainContest :key="language" />
        <Registration :key="language" />
        <Biography :key="language" />
    </div>
</template>

<script setup>
import MainHead from "../components/Main/MainHead.vue"
import About from "../components/Main/About.vue";
import Program from "../components/Main/Program.vue";
import Directions from "../components/Main/Directions.vue";
import MainContest from "../components/Main/MainContest.vue"
import Registration from "../components/Main/Registration.vue";
import Biography from "../components/Main/Biography.vue"
import { useStore } from "vuex";
import { ref, computed, provide } from "vue";

const name = "Main";

const store = useStore();

const language = computed(() => store.state.currentLanguage);
</script>

<style lang="scss"></style>